import React from "react"
import { graphql } from 'gatsby'
import SEO from "../components/Seo/Seo"
import FaqItem from '../components/FaqItem/FaqItem';
import Layout from "../components/Layout/Layout"
import PageHeader from '../components/Layout/PageHeader/PageHeader'
import SectionPageMargin from '../components/Layout/Section/SectionPageMargin'
import styled from 'styled-components'

const FAQPage = ({ location, data }) => 
  (
  <Layout>
    
    <SEO  title="Summon FAQs" 
          description="Summon Frequently Asked Questions about our full concierge service for selling your car."
          location={location}
    />

    <PageHeader 
      title="Frequently asked questions" 
      text="Summon is a concierge service for selling your car prestige privately. 
            We take what is a laborious and frustrating process - and make it simple and easy. 
            Currently we are accepting cars valued at over $50,000 in Melbourne and Sydney."
    />

    <SectionPageMargin>
      <Wrapper>
        { data.allContentfulFaq.edges.map(({node}, i) =>
            <FaqItem 
              key={i} 
              question={node.question.question} 
              answer={node.answer.answer}
              />
        )}
      </Wrapper>
    </SectionPageMargin>

  </Layout>
)

export default FAQPage

export const query = graphql`
  query {
  allContentfulFaq(sort: {order: ASC, fields: createdAt}) {
    edges {
      node {
        question {
          question
        }
        answer {
          answer
        }
      }
    }
  }
}`

const Wrapper = styled.div`
display: block;
li {
  padding-bottom: 15px;
}
`;


