import React from 'react'
import * as Markdown from 'react-markdown'
import Collapsible from 'react-collapsible';

import './FaqItem.css';

const FaqItem = (props) => (

    <Collapsible classParentString="FAQ-Collapsible" trigger={props.question}>
      <Markdown source={props.answer} />
    </Collapsible>

  )
  
  export default FaqItem

 